@import "../../styles/shared";

.content {
  @include from-desktop-up {
    margin-top: -$header-height;
  }
}

.partners {
  display: grid;
  align-items: center;
  text-align: center;
  margin-bottom: 64px;

  @include from-desktop-up {
    margin-block: 80px;
  }
  
  div {
    margin-inline: auto;
  }

  .contain {
    img {
      object-fit: contain;
    }
  } 
}
